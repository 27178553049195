import MailIcon from '-!react-svg-loader!feather-icons/dist/icons/mail.svg'
import { Link } from 'gatsby'
import React from 'react'
import CSS from './ContactLinkSection.module.css'

const ContactLinkSection = () => {
  return (
    <section className={CSS.root}>
      <div className={CSS.box}>
        <MailIcon className={CSS.icon} viewBox='0 0 24 24' />
        <p>
          Have a great idea in mind? We would love to work with you to make it
          happen.
        </p>
        <Link className={CSS.button} to='/work-with-us'>
          Contact us
        </Link>
      </div>
    </section>
  )
}

export default ContactLinkSection
