import { graphql } from 'gatsby'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'
import React from 'react'
import Helmet from 'react-helmet'
import ArticleLayout from '../layouts/ArticleLayout'

/**
 * Article template. This is used directly on gatsby-node.js.
 *
 * References:
 *
 * - https://www.gatsbyjs.org/docs/mdx/programmatically-creating-pages/#make-a-template-for-your-posts
 */

export default function ArticleTemplate({ data }: Props) {
  const { mdx } = data
  const { frontmatter, excerpt } = mdx
  const { title, description } = frontmatter

  const desc = description || excerpt

  return (
    <ArticleLayout draft={frontmatter.draft || false}>
      <Helmet>
        <title>{title}</title>
        {desc ? <meta name='description' content={desc} /> : null}
        {desc ? <meta property='og:description' content={desc} /> : null}
      </Helmet>
      <MDXRenderer>{mdx.code.body}</MDXRenderer>
    </ArticleLayout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      excerpt
      frontmatter {
        path
        title
        draft
        description
      }
      code {
        body
      }
    }
  }
`

interface Props {
  data: {
    mdx: {
      excerpt: string
      frontmatter: {
        path?: string
        title?: string
        draft?: boolean
        description: string
      }
      code: {
        body: string
      }
    }
  }
}
