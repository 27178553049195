import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import LocationSection from '../components/LocationSection'
import { NavArea, NavLayout } from '../components/Navigation'
import ContactLinkSection from '../sections/ContactLinkSection'
import LargeFooterSection from '../sections/LargeFooterSection'
import MarkdownSection from '../sections/MarkdownSection'

/**
 * A layout for an article.
 *
 * For the template equivalent (ie, to be used in createPages()), see `ArticleTemplate`.
 *
 * @example
 *     <ArticleLayout>
 *       <h1>Hello!</h1>
 *     </ArticleLayout>
 */

const ArticleLayout = (props: Props) => {
  const { children, draft } = props

  return (
    <Layout>
      {draft ? (
        <Helmet>
          <meta name='robots' content='noindex' />
        </Helmet>
      ) : null}

      <NavLayout>
        <NavArea light id='main'>
          <MarkdownSection>{children}</MarkdownSection>
        </NavArea>

        <NavArea dark id='location'>
          <ContactLinkSection />
          <LocationSection />
          <LargeFooterSection />
        </NavArea>
      </NavLayout>
    </Layout>
  )
}

interface Props {
  children: React.ReactNode
  draft?: boolean
}

export default ArticleLayout
